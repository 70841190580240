import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/shared/button";
//import { Authenticator } from '@aws-amplify/ui-react';
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useSelector, useDispatch } from 'react-redux'
import PageBreadcrumb from "../components/pagebreadcrumb";
import { Auth, API } from "aws-amplify";
import { setUser } from "../state/userSlice.js";
import { getUserProfile } from "../graphql/queries";
import { createUserProfile } from "../graphql/mutations";
const LoginPage = ({ data, location, pageContext }) => {
    const authenticatorRef = React.useRef()
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    //const [authState, setAuthState] = React.useState();
    const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()
    React.useEffect(() => {
      setTimeout(() => {
				authenticatorRef?.current?.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
					inline: "start"
				});
			}, 1000);
    });
    React.useEffect(() => {
      onAuthUIStateChange((nextAuthState, authData) => {
          if (nextAuthState === "signedin") {
              API.graphql({
                  query: getUserProfile,
                  variables: { id: authData.username },
              })
                  .then((result) => {
                      dispatch(
                          setUser({
                              id: authData.username,
                              email: authData.attributes.email,
                              profile: result.data.getUserProfile,
                          })
                      );
                  })
                  .catch((err) => console.log(err));
              dispatch(
                  setUser({
                      id: authData?.username,
                      email: authData?.attributes?.email,
                  })
              );
          } else {
              dispatch(setUser(null));
          }
      });
    }, []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title="Authentication" pathname="/auth" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Authentication"
            />
            <div>
                <div ref={authenticatorRef} className="container text-center">
                    <AmplifyAuthenticator usernameAlias="email">
                      <AmplifySignIn slot="sign-in" federated="" usernameAlias="email" />
					  <AmplifySignUp
							usernameAlias="email"
							slot="sign-up"
							formFields={[
							{ type: 'email' },
							{ type: 'password' },
							]}
					  />
                      <div>
                        <div className="pb-60">
                          <p>Hello, {user?.profile?.name || user?.email}</p>
                          <AmplifySignOut />
                        </div>
                      </div>
                    </AmplifyAuthenticator>
                </div>
            </div>
        </Layout>
    );
};

LoginPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query LoginPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
    }
`;
export default LoginPage;
